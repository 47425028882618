import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Pierre VERBARE | Cyber Security Engineer',
  lang: 'fr',
  description:
    "Welcome to my website, Pierre VERBARE, I'm a cyber security engineer at Sopra Steria, french leader of digital transformation",
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: 'Pierre VERBAERE',
  subtitle: "I'm a cyber security engineer at Sopra Steria",
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: 'AvatarMaker.png',
  paragraphOne:
    "Welcome to my personal website, I'm Pierre VERBAERE, a cybersecurity engineer. I'm working at Sopra Steria : intrusion detection, threat analysis and vulnerability reporting are my main subjects.",
  paragraphTwo:
    'In my free time, I play with all kinds of tools I can find on Cyber community to learn new (or old) trends of threats.',
  paragraphThree:
    "When I'm not on my computer, I enjoy the joys of the sea (windsurf, sailing, fishing), play sports (running) and serve my master, my lovely cat.",
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'MPFW.jpg',
    title: 'My Personal Firewall',
    info:
      "My Personal Firewall is an idea I get with my work, a lot of travels in train, nights in hotel. All these company give access to Internet with public wifi but I did't trust these network to connect laptop.",
    info2:
      'Here come the project to build a firewall with Raspberry Pi Zero W and USB stick : the Raspberry Pi connect to the public wifi and share Internet with my laptop by USB with iptables filters',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'rpi-cluster.jpeg',
    title: 'Raspberry Pi Cluster',
    info:
      'I love Raspberry Pi and the infinity projects that offer. So when I would like to set up a security management in my personal network/devices I choose to do that with it.',
    info2:
      'I decide to build a Docker Swarm cluster to host all services that I will use to monitor my network and devices (NIDS, HIDS, SIEM).',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'kidmalin.jpg',
    title: 'Kidmalin',
    info:
      "It's an old personal project that I want to rebuild. When I was in Engineering school, I work on a website for children in elementary school, to help them to learn maths and French with digital.",
    info2: 'Today I would like to rebuild it from scratch with my new abilities.',
    url: 'https://www.kidmalin.com',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Something to say or share with me? Awesome!',
  btn: "Let's Talk",
  email: 'contact@verbaere.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/PVerbaere',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/pierre-verbaere-9b754a127/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://gitea.verbaere.com/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
